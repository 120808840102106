import React from 'react';
import MaterialUITypography from '../Typography';
import { bodyPropertyTypes, BodyProperties } from './Body1';

const Body2 = ({
  children,
  tag = 'p',
  className,
  variant = 'body2',
  ...properties
} : BodyProperties) => (
  <MaterialUITypography
    tag={tag}
    className={className}
    variant={variant}
    color="textSecondary"
    {...properties}
  >
    {children}
  </MaterialUITypography>
);

Body2.propTypes = bodyPropertyTypes;

const Body2Wrapper = (properties: BodyProperties) => (
  <Body2 {...properties} />
);

export default Body2Wrapper;
