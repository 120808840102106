import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import MaterialUITypography from './Typography';

const Overline = ({
  children, tag, className, variant,
}) => (
  <MaterialUITypography
    tag={tag}
    className={className}
    variant={variant}
    color="primary"
  >
    {children}
  </MaterialUITypography>
);

Overline.defaultProps = {
  children: undefined,
  tag: 'span',
  className: undefined,
  variant: 'overline',
};

Overline.propTypes = {
  /** Sets the content */
  children: PropTypes.node,
  /** Change the default html tag */
  tag: PropTypes.string,
  /** Sets the classname */
  className: PropTypes.string,
  /**  Applies the theme typography styles * */
  variant: PropTypes.string,
};

const StyledOverline = styled(Overline)`
  && {
    line-height: 1rem;
    letter-spacing: 0.07rem;
  }
`;

export default StyledOverline;
